import React from "react";

import Countdown from "../components/Countdown";

import "../global.css";
import "./StreamView.css";
import StreamDone from "../img/streamDone.svg";
import TechDiff from "../img/techdiff.svg";
import NotFound from "../img/notfound.svg";
import { Link } from "react-router-dom";
import GDPRStreamFrame from "./GDPRStreamFrame";

class StreamView extends React.Component {
	constructor(props) {
		super(props);

		this.startSendRequest = this.startSendRequest.bind(this);
	}

	render() {
		switch (this.props.status) {
			case "active":
				return (
					<div className="streamView preventCollapse">
						<GDPRStreamFrame
							service={"Twitch"}
							channel={this.props.streamChannel}
						/>
					</div>
				);
			case "waiting":
				return (
					<div className="streamView waiting preventCollapse">
						<h1>Bald geht's los!</h1>
						<Countdown scheduledDate={this.props.scheduledDate} onScheduledTimeReached={this.props.onScheduledTimeReached}/>
						<p>
							Wir freuen uns auf dich! Leider musst du aber noch etwas geduld haben, bis die Übertragung
							beginnt. Der Stream beginnt am&nbsp;
							{new Date(this.props.scheduledDate).getDate().toTimeLength()}.
							{new Date(this.props.scheduledDate).getMonth().toTimeLength()}.
							{new Date(this.props.scheduledDate).getFullYear()} um {this.getTime(this.props.scheduledDate)} Uhr,
							15 Minuten vor dem Beginn der Veranstaltung.<br/><br/>
							So hast du die Gelegenheit alles einzurichten, bevor es losgeht.
						</p>
					</div>
				);
			case "ended":
				return (
					<div className="streamView ended preventCollapse">
						<h1>Vielen Dank für's Zuschauen!</h1>
						<img src={StreamDone} alt=""/>
						<p>
							Dieser Stream ist beendet. Möchten Sie eine Aufzeichnung?
							Gerne schauen wir, was wir für Sie tun können. Bitte geben Sie Ihre E-Mail Adresse an und wir melden uns bei Ihnen.
						</p>
						<div className="requestRecording" onClick={this.startSendRequest}>Aufzeichnung anfragen</div>
					</div>
				);
			case "techdiff":
				return (
					<div className="streamView techdiff preventCollapse">
						<h1>Wir haben technische Schwierigkeiten</h1>
						<img src={TechDiff} alt=""/>
						<p>
							Leider haben wir Probleme ein Livebild zu übertragen.<br />Bitte haben Sie Geduld, wir arbeiten
							mit Hochdruck an einer Lösung.
						</p>
					</div>
				);
			case "notfound":
				return (
					<div className="streamView notFound preventCollapse">
						<h1>Dieser Stream existiert nicht</h1>
						<img src={NotFound} alt=""/>
						<p>
							Wir können diesen Stream leider nicht finden. Bitte stellen Sie sicher, dass Sie den richtigen Link in die Adresszeile eingegeben haben
							(achten Sie auf Groß-, Kleinschreibung) oder versuchen Sie es später erneut.<br /><br />Um unser Sortiment an anstehenden Streams
							zu sehen, klicken Sie <Link to={"/"}>hier</Link>.
						</p>
					</div>
				);
			default:
				return (
					<div className="streamView">
						An error occurred.
					</div>
				);
		}
	}

	getTime() {
		let hrs = new Date(this.props.scheduledDate).getHours().toString();
		if (hrs.length === 1) {
			hrs = "0" + hrs;
		}

		let mins = new Date(this.props.scheduledDate).getMinutes().toString();
		if (mins.length === 1) {
			mins = "0" + mins;
		}
		return hrs+":"+mins;
	}

	startSendRequest() {
		window.location.href = `mailto:anfrage@nschroetler.de?subject=Bitte%20um%20Aufzeichnung%20von%20${this.props.streamName}&body=Hallo%2C%0D%0Amein%20Name%20ist%20%5BIhr%20Name%5D%20und%20ich%20bitte%20um%20eine%20Aufzeichnung%20des%20Livestreams%20von%20https%3A%2F%2Fallround.live%2F${this.props.streamName}.%0D%0A%0D%0ADiese%20h%C3%A4tte%20ich%20gerne%2C%20weil%20...`
	}
}

export default StreamView;

// eslint-disable-next-line
Number.prototype.toTimeLength = function() {
	if (this.toString().length === 1) {
		return "0" + this.toString();
	}
	else {
		return this.toString();
	}
}


