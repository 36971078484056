import React from "react";

import "../global.css";
import "./StreamView.css";

class Countdown extends React.Component {
	countdownInterval;

	constructor(props) {
		super(props);

		this.state = {
			seconds: null,
			minutes: null,
			hours: null,
			days: null
		}
	}

	componentDidMount() {
		this.countdownInterval = setInterval(this.tick.bind(this), 1000);
		this.tick();
	}

	componentWillUnmount() {
		clearInterval(this.countdownInterval);
	}

	render() {
		return(
			<ul className="countdown">
				<li><span>{this.state.days}</span>Tage</li>
				<li><span>{this.state.hours}</span>Stunden</li>
				<li><span>{this.state.minutes}</span>Minuten</li>
				<li id="sec"><span>{this.state.seconds}</span>Sekunden</li>
			</ul>
		);
	}

	tick() {
		const
			second = 1000,
			minute = second * 60,
			hour = minute * 60,
			day = hour * 24;

		let countDown = new Date(this.props.scheduledDate).getTime();
		let now = new Date().getTime();
		let distance = countDown - now;

		//do something later when date is reached
		if (distance < 0) {
			clearInterval(this.countdownInterval);
			this.props.onScheduledTimeReached();
		}
		else {
			let newState = {
				seconds: Math.floor((distance % (minute)) / second).toString(),
				minutes: Math.floor((distance % (hour)) / (minute)).toString(),
				hours: Math.floor((distance % (day)) / (hour)).toString(),
				days: Math.floor(distance / (day)).toString()
			}

			if(newState.seconds.length === 1) {
				newState.seconds = 0 + newState.seconds
			}
			if(newState.minutes.length === 1) {
				newState.minutes = 0 + newState.minutes
			}
			if(newState.hours.length === 1) {
				newState.hours = 0 + newState.hours
			}
			if(newState.days.length === 1) {
				newState.days = 0 + newState.days
			}

			this.setState(newState);
		}
	}
}

export default Countdown;