import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";

import StreamViewer from "./pages/StreamViewer";
import Home from "./pages/Home";
import NotFound from "./pages/errors/NotFound";
import ImpressAndDisclaimer from "./pages/ImpressAndDisclaimer";
import RecordingInfo from "./pages/RecordingInfo";
import DataProtection from "./pages/DataProtection";

function App() {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <Route path="/legal/impressum" exact component={ImpressAndDisclaimer}/>
                    <Route path="/legal/datenschutz" exact component={DataProtection}/>
                    <Route path="/legal/aufnahmen" exact component={RecordingInfo}/>
                    <Route path="/:streamName" exact component={StreamViewer}/>
                    <Route path="/" exact component={Home} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
