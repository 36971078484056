import React from "react";

import "../global.css";
import "./Sidepanel.css";
import logo from "../img/logo.png";
import icon from "../img/iconLive.png";
import {Link} from "react-router-dom";

class Sidepanel extends React.Component {
	render() {
		return (
			<aside className="sidepanel">
				<Link to={"/"}><img src={logo} className="logo" alt="allroundLive Logo"/></Link>

				{this.renderStreamDetails(this.props)}

				{/*<span id="small">*/}
				{/*	<img src={icon} className="icon" alt="allroundLive Icon"/>*/}
				{/*</span>*/}

				<div className="legal">
					<p>allroundDigital Live ist ein Dienst<br/>von Niklas Schrötler Development</p>
					<ul>
						<li><Link to={"/legal/impressum"}>Impressum</Link></li>
						<li><Link to={"/legal/datenschutz"}>Datenschutz</Link></li>
					</ul>
				</div>
			</aside>
		);
	}

	renderStreamDetails(props) {
		if(props.isBesideStream) {
			console.log("Beside")
			return (
				<div className="streamDetails">
					<h2 className="streamTitle">{props.streamTitle}</h2>
					<p className="streamLocation">{props.streamLocation}</p>
					<p className="streamDescription">{props.streamDescription}</p>
				</div>
			);
		}
	}
}

export default Sidepanel;
