import React from "react";
import MenuIcon from "./../img/menu.svg";
import CloseIcon from "./../img/close.svg";

import "../global.css";
import "./SplitView.css";

class SplitView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			"fmbImage": MenuIcon
		}

		this.toggleMenu = this.toggleMenu.bind(this);
	}


	render() {
		return (
			<div className="wrapper">
				<div className="fmb" onClick={this.toggleMenu}>
					<img src={this.state.fmbImage}/>
				</div>
				<div className="left">
					{this.props.left}
				</div>
				<div className="right">
					{this.props.right}
				</div>
			</div>
		);
	}

	toggleMenu() {
		if(document.querySelectorAll(".left")[0].classList.contains("show")) {
			// Is open
			document.querySelectorAll(".left")[0].classList.remove("show");
			this.setState({"fmbImage": MenuIcon});
		}
		else {
			// Is closed
			document.querySelectorAll(".left")[0].classList.add("show");
			this.setState({"fmbImage": CloseIcon});
		}
	}
}

export default SplitView;
