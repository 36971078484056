import * as React from "react";
import SplitView from "../components/SplitView";
import Sidepanel from "../components/Sidepanel";
import StreamView from "../components/StreamView";

class StreamViewer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            streamTitle: "",
            streamLocation: "",
            streamDescription: "",
            status: "waiting",
            streamChannel: "",
            scheduledDate: new Date(new Date().getTime() + 60000).toISOString()
        }

        this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() {
        this.refreshData();
        this.refInt = setInterval(this.refreshData, 30000);
    }

    componentWillUnmount() {
        clearInterval(this.refInt);
    }

    render() {
        return (
            <SplitView
                left={
                    <Sidepanel
                        isBesideStream={true}
                        streamTitle={this.state.streamTitle}
                        streamLocation={this.state.streamLocation}
                        streamDescription={this.state.streamDescription}
                    />
                }

                right={
                    <StreamView
                        status={this.state.status}
                        streamChannel={this.state.streamChannel}
                        scheduledDate={this.state.scheduledDate}
                        onScheduledTimeReached={() => {this.onScheduledTimeReached()}}
                        streamName={this.props.match.params.streamName.toLowerCase()}
                    />
                }
            />
        );
    }

    refreshData() {
        fetch("/configs/" + this.props.match.params.streamName.toLowerCase() + ".json")
            .then(res => res.json())
            .then(function(data) {
                try {
                    if (data.state === "nominal") {
                        let countDown = new Date(data.scheduledDate).getTime();
                        let now = new Date().getTime();
                        if (countDown - now <= 0) {
                            data.status = "active";
                        } else {
                            data.status = "waiting"
                        }
                    }
                    else {
                        data.status = data.state;
                        this.setState(data);
                    }

                    this.setState(data);
                }
                catch(e) {
                    this.setState({status: "notfound"})
                }
            }.bind(this))
            .catch(function(e) {
                this.setState({status: "notfound"})
            }.bind(this));
    }

    onScheduledTimeReached() {
        this.refreshData();
    }
}

export default StreamViewer;