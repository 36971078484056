import * as React from "react";
import "./Home.css"
import Logo from "./../img/logo.png";
import {Link} from "react-router-dom";

class Home extends React.Component {
    render() {
        return (
            <div className="wrapper">
                <div className={"Home"}>
                <img src={Logo} className="homeLogo" alt={"allroundDigital Live"}/>
                <h3>Anstehende Liveübertragungen:</h3>
                <ul>
                    <li>
                        <Link to={"/fragy"}>
                            <h1>Abschlussfeier Abiturjahrgang 2019/20</h1>
                            <h2>Live aus dem Forum Zülpich</h2>
                            <p>Aufgrund der aktuellen Situation und den daraus resultierenden Kontaktbeschränkungen freut sich die Stufe Q2 des Franken Gymnasium Zülpich Sie dieses Jahr online begrüßen zu dürfen.</p>
                        </Link>
                    </li>
                </ul>
                </div>
            </div>
        );
    }
}

export default Home;