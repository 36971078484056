import React, {Component} from 'react';
import "./GDPRStreamFrame.css";

import Icon from "./../img/iconLive.png";

class GDPRStreamFrame extends Component {
	constructor(props) {
		super(props);

		this.state = {
			status: "waiting_for_opt-in"
		}

		this.sendConsent = this.sendConsent.bind(this);
	}


	render() {
		switch(this.state.status) {
			case "waiting_for_opt-in":
				return (
					<div className={"GDPRStreamFrame waiting"}>
						<img src={Icon} alt={""} />
						<h2>Der Stream hat begonnen</h2>
						<p>
							Gleich kann es mit dem Stream losgehen. Vorher benötigen wir allerdings noch Ihr Einverständnis.
							Um Ihnen die bestmögliche Erfahrung zu bieten setzen wir bei Liveübertragungen auf die Dienste
							von {this.props.service}. Dazu müssen wir jedoch die Dienste von {this.props.service} auf diese
							Website einbinden, wodurch Daten von Ihnen an diesen weitergegeben werden können. Sind Sie damit
							einverstanden klicken Sie unten auf "Jetzt Ansehen" und wir starten den Stream für Sie. Um mehr
							über die Datenverarbeitung von {this.props.service} zu erfahren, lesen Sie sich unsere
							Datenschutzerklärung durch. Dort finden Sie zusätzlich Links zu den Datenschutzerklärungen von
							{this.props.service}. Wir danken für Ihr Verständnis und wünschen viel Spaß beim schauen.
						</p>
						<button onClick={this.sendConsent}>Ich bin einverstanden</button>
					</div>
				);
			case "opted-in":
				if(this.props.service === "Twitch") {
					return (
						<iframe
							title="Livestream"
							className="stream"
							src={`https://player.twitch.tv/?channel=${this.props.channel}&parent=allround.live&parent=localhost&autoplay=1&branding=false`}
							frameBorder="0"
							allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
					);
				}
				else {
					return "Unknown Livestream supplier \"Twitch\"";
				}
			default:
				return "error";
		}
	}

	sendConsent() {
		this.setState({status: "opted-in"})
	}
}

export default GDPRStreamFrame;