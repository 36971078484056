import React from "react";

import "./Legal.css";
import "./../global.css";

function RecordingInfo() {
	return (
		<div className={"wrapper"}>
			<h1>Foto- und Videoaufnahmen</h1>
			<p>Im Rahmen der Veranstaltung werden Bildaufnahmen erstellt. Nachfolgend informieren wir Sie über die Art
				und Zwecke der Bildaufnahmen, über die Rechtsgrundlagen und Ihre Rechte.</p>
			<strong id="m775">Die Bildaufnahmen werden zu den folgenden Zwecken verwendet</strong>: Bereitstellung der
			Aufnahmen für die Teilnehmer; Interne Bilddatenbank, bzw. Bildarchiv; Verwendung im internen Netzwerk
			(Intranet); Unsere eigenen Printmedien und vergleichbaren Publikationen; Öffentliche Berichterstattung über
			die Veranstaltung; Verwendung auf unserer Website; Für jedermann zugängliche und nutzbare Bilddatenbank,
			bzw. Bildarchiv (z.B. Bilddownloadbereich mit einer Medienauswahl für Berichtszwecke); Verwendung durch
			Presse und Medien; Verwendung in Videoproduktionen; Videoberichterstattung zur Veranstaltung; Jahrbücher und
			vergleichbare Aufzeichnungen; Schülerzeitung;
			<p>Des weiteren werden die Aufnahmen ggf. live über die
			Plattform Twitch.tv oder vergleichbaren Plattformen übertragen.</p>
			<p><strong>Rechtsgrundlagen der Aufnahmen</strong>: Berechtigte Interessen an der Nutzung der Aufnahmen zu
				den vorgenannten Zwecken gem. Art. 6 Abs. 1 S. 1 lit. f., 85 DSGVO in Verbindung mit §§ 22, 23 KUG.</p>
			<strong id="m786">Hinweise auf Bildaufnahmen Dritter</strong>: Bildaufnahmen können auch von den Teilnehmern
			der Veranstaltung in deren eigener Verantwortung erstellt werden. Wir bitten die Teilnehmer um gegenseitige
			Rücksichtnahme auf ihre Persönlichkeitsrechte. Während der Veranstaltung können Medienvertreter vor Ort sein
			und in eigener Verantwortung Bildaufnahmen erstellen. <strong><p>Wir bitten Sie besondere Rücksicht auf
			minderjährige Personen zu nehmen und diese nicht oder nur mit der Einwilligung der Erziehungsberechtigten,
			bzw. bei Jugendlichen ab 16. Lebensjahr mit deren Einwilligung abzulichten.</p></strong><p>
			<strong>Verantwortlicher:</strong></p>
			<p>Niklas Schrötler<br />Xantenerstraße 11<br />53909 Zülpich</p>
			<p><strong>E-Mail-Adresse:</strong> <a href="mailto:info@schroetlerdev.de">info@schroetlerdev.de</a></p>
			<p><strong>Telefon:</strong> +49 2252 8090447</p>
			<p><strong>Datenschutzerklärung:</strong> <a href="https://allround.live/legal/datenschutz"
														 target="_blank">https://allround.live/legal/datenschutz</a></p>
			<p><strong id="m770">Ihre Rechte</strong>: Sie können Ihr Recht auf Auskunft oder Berichtigung, Löschung und
				Einschränkung der Verarbeitung der Fotografien geltend machen und können sich bei der zuständigen
				Aufsichtsbehörde beschweren. Sie können der Verarbeitung von Aufnahmen und Daten, die Sie betreffen
				jederzeit widersprechen.</p>
			<p className="seal"><a href="https://datenschutz-generator.de/?l=de"
								   title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
								   target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem
				Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>
		</div>
	);
}

export default RecordingInfo;